<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: headerBreadcrumbItem1.name }">
              {{ headerBreadcrumbItem1.text }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: headerBreadcrumbItem2.name, params: headerBreadcrumbItem2.params }">
              {{ headerBreadcrumbItem2.text }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active>設定所屬夥伴公司</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-overlay :show="showLoading">
      <b-card>
        <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
          <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
            設定所屬夥伴公司
          </h4>
        </div>
        <b-card-body>
          <div class="row">
            <div class="col-12 col-xl-8 mt-3">
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="姓名"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="text"
                  v-model="staff.name"
                  disabled
                ></b-form-input
              ></b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="員工編號"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="text"
                  v-model="staff.employee_code"
                  disabled
                ></b-form-input
              ></b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="所屬夥伴公司"
              >
                <div v-for="(partner, index) in partners" :key="partner.id" class="mb-2 d-flex">
                  <b-input-group class="col-7 pl-0">
                    <b-form-select
                      class="form-control"
                      v-model="partner.id"
                    >
                      <b-form-select-option :value="null" disabled
                      >{{ displayText('partner-worker-select', '請選擇夥伴公司') }}
                      </b-form-select-option
                      >
                      <b-form-select-option
                        v-for="(partner, index) in partnerOptions"
                        :key="index"
                        :value="partner.id"
                      >{{ displayPartnerName(partner) }}
                      </b-form-select-option
                      >
                    </b-form-select>
                    <b-input-group-text class="text-danger">
                      <span @click="removePartner(index)">刪除</span>
                    </b-input-group-text>
                  </b-input-group>
                </div>

                <b-button class="btn-inverse-primary" @click="addPartner">新增</b-button>
              </b-form-group
              >
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-12">
                  <div class="float-right">
                    <b-button
                      class="mr-3"
                      @click="handleCancel"
                      variant="outline-danger"
                      >返回</b-button
                    >
                    <b-button @click="submit" variant="success">更新</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import staffApi from "@/apis/staff";
import {mapState, mapGetters} from 'vuex';
import partnerStaff from "@/apis/partner-staff";
import { displayText } from '@/utils/dashboardUtils'

export default {
  computed: {
    ...mapState('auth', {
      user: state => state.user,
    }),
    ...mapGetters('general', [
      'myBranches',
      'organization'
    ]),
    fromBranchPage() {
      return !!this.$route.params.branchID;
    },
    fromPartnerPage() {
      return !!this.$route.params.partnerId;
    },
    headerBreadcrumbItem1() {
      if (this.fromBranchPage) {
        return { name: 'Branches', text: '分店管理' };
      } else if (this.fromPartnerPage) {
        return { name: 'PartnerList', text: '夥伴管理' };
      } else {
        return { name: 'StaffList', text: '帳號管理' };
      }
    },
    headerBreadcrumbItem2() {
      if (this.fromBranchPage) {
        return {
          name: 'BranchStaffs',
          params: { branchID: this.$route.params.branchID },
          text: '店內帳號'
        };
      } else if (this.fromPartnerPage) {
        return {
          name: 'PartnerStaffList',
          params: { partnerId: this.$route.params.partnerId },
          text: '夥伴帳號列表'
        };
      } else {
        return {
          name: 'StaffList',
          params: {},
          text: '帳號列表'
        };
      }
    },
  },
  data() {
    return {
      staff: {
        name: null,
        employee_code: null,
      },
      showLoading: true,
      partners: [],
      partnerOptions: [],
    };
  },
  mounted() {
    this.fetchStaff();
    this.fetchPartners();
  },
  methods: {
    displayText,
    async fetchPartners() {
      const { data } = await partnerStaff.getPartnerList(this.organization, { need_origin_id: true });
      this.partnerOptions = data.data;
    },
    async fetchStaff() {
      const staff_id = this.$route.params.staff_id;

      let response = await staffApi.getOneStaff(staff_id);
      if (response.status === 200 && response.data.data) {
        this.staff = response.data.data;
        this.partners = this.staff.partners;
      } else {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "取得帳號資料失敗",
        });
      }
      this.showLoading = false;
    },
    async submit() {
      this.showLoading = true;

      try {
        let response = await partnerStaff.setPartners(
          this.organization,
          this.$route.params.staff_id,
          { partners: this.partners }
        );
        if (response.status && response.status === 200) {
          this.navigateToRelevantPage();
        }
      } catch (error) {
        console.error(error);
        alert("設定所屬夥伴公司失敗");
      }

      this.showLoading = false;
    },
    handleCancel() {
      this.navigateToRelevantPage();
    },
    addPartner() {
      this.partners.push({ id: null, order: null });
    },
    removePartner(index) {
      this.partners.splice(index, 1);
    },
    displayPartnerName(partner) {
      return partner.partner_code + ' ' + partner.name
    },
    navigateToRelevantPage() {
      if (this.fromBranchPage) {
        this.$router.push({
          name: "BranchStaffs",
          params: { branchID: this.$route.params.branchID },
        });
      } else if (this.fromPartnerPage) {
        if (this.from) {
          this.$router.push(this.from.fullPath);
        } else {
          this.$router.push({
            name: "PartnerStaffList",
            params: { partnerId: this.$route.params.partnerId },
          });
        }
      } else {
        this.$router.push({ name: "StaffList" });
      }
    }
  },
};
</script>

<style>
.table-responsive {
  min-height: 300px;
}
</style>
